const state =
{
    pcRoutes: [
        {
            // 页面逻辑
            path: '/',
            name: 'HomePage',
            component: () => import('@/pc/page/home/HomePage.vue')
        },
        {
            path: '/showroom',
            name: 'ShowroomPage',
            component: () => import('@/pc/page/showroom/ShowroomPage-Layout.vue')
        },
        {
            path: '/disclaimer',
            name: 'DisclaimerPage',
            component: () => import('@/pc/page/disclaimer/DisclaimerPage.vue')
        },
        {
            path: '/customerCase/show',
            name: 'CustomerCasePage',
            component: () => import('@/pc/page/customerCase/CustomerCasePage.vue')
        },
        {
            path: '/customerCase/feedback',
            name: 'FeedbackPage',
            component: () => import('@/pc/page/customerCase/FeedbackPage.vue')
        },
        {
            path: '/partner/brand',
            name: 'PartnerPage',
            component: () => import('@/pc/page/partner/PartnerPage.vue')
        },
        {
            path: '/partner/business',
            name: 'BusinessPage',
            component: () => import('@/pc/page/partner/BusinessPage.vue')
        },
        {
            path: '/productionLine',
            name: 'ProductionLinePage',
            component: () => import('@/pc/page/productionLine/ProductionLinePage.vue')
        },
        {
            path: '/productionLine/molding',
            name: 'MoldingPage',
            component: () => import('@/pc/page/productionLine/MoldingPage.vue')
        },
        {
            path: '/productionLine/menShoes',
            name: 'MenShoesPage',
            component: () => import('@/pc/page/productionLine/MenShoesPage.vue')
        },
        {
            path: '/dedicatedEquipment',
            name: 'DedicatedEquipmentPage',
            component: () => import('@/pc/page/dedicatedEquipment/DedicatedEquipmentPage.vue')
        },
        {
            path: '/dedicatedEquipment/polishedEquipment',
            name: 'PolishedEquipmentPage',
            component: () => import('@/pc/page/dedicatedEquipment/PolishedEquipmentPage.vue')
        },
        {
            path: '/dedicatedEquipment/glueEquipment',
            name: 'GlueEquipmentPage',
            component: () => import('@/pc/page/dedicatedEquipment/GlueEquipmentPage.vue')
        },
        {
            path: '/dedicatedEquipment/sprayEquipment',
            name: 'SprayEquipmentPage',
            component: () => import('@/pc/page/dedicatedEquipment/SprayEquipmentPage.vue')
        },
        {
            path: '/technology/overview',
            name: 'TechnologyPage',
            component: () => import('@/pc/page/technology/TechnologyPage.vue')
        },
        {
            path: '/technology/digitizing',
            name: 'DigitizingPage',
            component: () => import('@/pc/page/technology/DigitizingPage.vue')
        },
        {
            path: '/technology/digitizing/introduce',
            name: 'DigitizingIntroducePage',
            component: () => import('@/pc/page/technology/DigitizingIntroducePage.vue')
        },
        {
            path: '/technology/intelligent',
            name: 'IntelligentPage',
            component: () => import('@/pc/page/technology/IntelligentPage.vue')
        },
        {
            path: '/technology/intelligent/introduce',
            name: 'IntelligentIntroducePage',
            component: () => import('@/pc/page/technology/IntelligentIntroducePage.vue')
        },
        {
            path: '/technology/automation',
            name: 'AutomationPage',
            component: () => import('@/pc/page/technology/AutomationPage.vue')
        },
        {
            path: '/technology/automation/introduce',
            name: 'AutomationIntroducePage',
            component: () => import('@/pc/page/technology/AutomationIntroducePage.vue')
        },
        {
            path: '/technology/iot',
            name: 'IotPage',
            component: () => import('@/pc/page/technology/IotPage.vue')
        },
        {
            path: '/technology/iot/introduce',
            name: 'IotIntroducePage',
            component: () => import('@/pc/page/technology/IotIntroducePage.vue')
        },
        {
            path: '/technology/mechanical',
            name: 'MechanicalPage',
            component: () => import('@/pc/page/technology/MechanicalPage.vue')
        },
        {
            path: '/companyRelated/companyProfile',
            name: 'CompanyProfilePage',
            component: () => import('@/pc/page/companyRelated/CompanyProfilePage.vue')
        },
        {
            path: '/companyRelated/employmentConcept',
            name: 'EmploymentConceptPage',
            component: () => import('@/pc/page/companyRelated/EmploymentConceptPage.vue')
        },
        {
            path: '/companyRelated/talentDemand',
            name: 'TalentDemandPage',
            component: () => import('@/pc/page/companyRelated/TalentDemandPage.vue')
        },
        {
            path: '/companyRelated/developmentHistory',
            name: 'DevelopmentHistoryPage',
            component: () => import('@/pc/page/companyRelated/DevelopmentHistoryPage.vue')
        },
        {
            path: '/companyRelated/companyCulture',
            name: 'CompanyCulturePage',
            component: () => import('@/pc/page/companyRelated/CompanyCulturePage.vue')
        },
        {
            path: '/companyRelated/honor',
            name: 'HonorPage',
            component: () => import('@/pc/page/companyRelated/honor/HonorPage.vue')
        },
        {
            path: '/companyRelated/honor/2020072501',
            name: 'HonorPage1',
            component: () => import('@/pc/page/companyRelated/honor/HonorPage1.vue')
        },
        {
            path: '/companyRelated/honor/2021030401',
            name: 'HonorPage2',
            component: () => import('@/pc/page/companyRelated/honor/HonorPage2.vue')
        },
        {
            path: '/companyRelated/honor/2021051401',
            name: 'HonorPage3',
            component: () => import('@/pc/page/companyRelated/honor/HonorPage3.vue')
        },
        {
            path: '/companyRelated/honor/2021081901',
            name: 'HonorPage4',
            component: () => import('@/pc/page/companyRelated/honor/HonorPage4.vue')
        },
        {
            path: '/companyRelated/honor/2021082701',
            name: 'HonorPage5',
            component: () => import('@/pc/page/companyRelated/honor/HonorPage5.vue')
        },
        {
            path: '/companyRelated/honor/2022012601',
            name: 'HonorPage6',
            component: () => import('@/pc/page/companyRelated/honor/HonorPage6.vue')
        },
        {
            path: '/companyRelated/news',
            name: 'NewsPage',
            component: () => import('@/pc/page/companyRelated/news/NewsPage.vue')
        },
        {
            path: '/companyRelated/news/2019091701',
            name: 'NewsPage1',
            component: () => import('@/pc/page/companyRelated/news/NewsPage1.vue')
        },
        {
            path: '/companyRelated/news/2019101601',
            name: 'NewsPage2',
            component: () => import('@/pc/page/companyRelated/news/NewsPage2.vue')
        },
        {
            path: '/companyRelated/news/2020011501',
            name: 'NewsPage3',
            component: () => import('@/pc/page/companyRelated/news/NewsPage3.vue')
        },
        {
            path: '/companyRelated/news/2020042501',
            name: 'NewsPage4',
            component: () => import('@/pc/page/companyRelated/news/NewsPage4.vue')
        },
        {
            path: '/companyRelated/news/2020052201',
            name: 'NewsPage5',
            component: () => import('@/pc/page/companyRelated/news/NewsPage5.vue')
        },
        {
            path: '/companyRelated/news/2020072501',
            name: 'NewsPage6',
            component: () => import('@/pc/page/companyRelated/news/NewsPage6.vue')
        },
        {
            path: '/companyRelated/news/2020082101',
            name: 'NewsPage7',
            component: () => import('@/pc/page/companyRelated/news/NewsPage7.vue')
        },
        {
            path: '/companyRelated/news/2021012001',
            name: 'NewsPage8',
            component: () => import('@/pc/page/companyRelated/news/NewsPage8.vue')
        },
        {
            path: '/companyRelated/news/2021013001',
            name: 'NewsPage9',
            component: () => import('@/pc/page/companyRelated/news/NewsPage9.vue')
        },
        {
            path: '/companyRelated/news/2021032401',
            name: 'NewsPage10',
            component: () => import('@/pc/page/companyRelated/news/NewsPage10.vue')
        },
        {
            path: '/companyRelated/news/2021060201',
            name: 'NewsPage11',
            component: () => import('@/pc/page/companyRelated/news/NewsPage11.vue')
        },
        {
            path: '/companyRelated/news/2022040901',
            name: 'NewsPage12',
            component: () => import('@/pc/page/companyRelated/news/NewsPage12.vue')
        },
        {
            path: '/vendingInquiries',
            name: 'VendingInquiriesPage',
            component: () => import('@/pc/page/vendingInquiries/VendingInquiriesPage.vue')
        },
        //Net
        {
            path: '/net',
            name: 'NetHomePage',
            component: () => import('@/pc/page/net/home/HomePage.vue')
        },
        {
            path: '/net/partner',
            name: 'NetPartnerPage',
            component: () => import('@/pc/page/net/partner/PartnerPage.vue')
        },
        {
            path: '/net/productionLine',
            name: 'NetProductionLinePage',
            component: () => import('@/pc/page/net/productionLine/ProductionLinePage.vue')
        },
        {
            path: '/net/customerCase',
            name: 'NetCustomerCasePage',
            component: () => import('@/pc/page/net/customerCase/CustomerCasePage.vue')
        },
        {
            path: '/net/dedicatedEquipment',
            name: 'NetDedicatedEquipmentPage',
            component: () => import('@/pc/page/net/dedicatedEquipment/DedicatedEquipmentPage.vue')
        },
        {
            path: '/net/dedicatedEquipment/polishedEquipment',
            name: 'NetPolishedEquipmentPage',
            component: () => import('@/pc/page/net/dedicatedEquipment/PolishedEquipmentPage.vue')
        },
        {
            path: '/net/dedicatedEquipment/glueEquipment',
            name: 'NetGlueEquipmentPage',
            component: () => import('@/pc/page/net/dedicatedEquipment/GlueEquipmentPage.vue')
        },
        {
            path: '/net/dedicatedEquipment/sprayEquipment',
            name: 'NetSprayEquipmentPage',
            component: () => import('@/pc/page/net/dedicatedEquipment/SprayEquipmentPage.vue')
        },
        {
            path: '/net/technology/digitizing',
            name: 'NetDigitizingPage',
            component: () => import('@/pc/page/net/technology/DigitizingPage.vue')
        },
        {
            path: '/net/technology/intelligent',
            name: 'NetIntelligentPage',
            component: () => import('@/pc/page/net/technology/IntelligentPage.vue')
        },
        {
            path: '/net/technology/automation',
            name: 'NetAutomationPage',
            component: () => import('@/pc/page/net/technology/AutomationPage.vue')
        },
        {
            path: '/net/technology/iot',
            name: 'NetIotPage',
            component: () => import('@/pc/page/net/technology/IotPage.vue')
        },
        {
            path: '/net/technology/mechanical',
            name: 'NetMechanicalPage',
            component: () => import('@/pc/page/net/technology/MechanicalPage.vue')
        },
        {
            path: '/net/companyRelated/companyProfile',
            name: 'NetCompanyProfilePage',
            component: () => import('@/pc/page/net/companyRelated/CompanyProfilePage.vue')
        },
        {
            path: '/net/companyRelated/developmentHistory',
            name: 'NetDevelopmentHistoryPage',
            component: () => import('@/pc/page/net/companyRelated/DevelopmentHistoryPage.vue')
        },
        {
            path: '/net/companyRelated/companyCulture',
            name: 'NetCompanyCulturePage',
            component: () => import('@/pc/page/net/companyRelated/CompanyCulturePage.vue')
        },
        {
            path: '/404',
            name: '404Page',
            component: () => import('@/pc/page/404/404Page.vue')
        },
        {
            path: '/:pathMatch(.*)',
            redirect: '/404'
        }
    ],
    mobileRoutes: [
        { path: '/', redirect: '/index' },
        {
            path: '/index',
            name: 'HomePage',
            component: () => import('@/mobile/page/home/HomePage.vue')
        },
        {
            path: '/showroom',
            name: 'ShowroomPage',
            component: () => import('@/mobile/page/showroom/ShowroomPage-Button-Flow.vue')
        },
        {
            path: '/showroom/iot',
            name: 'ShowroomPageIot',
            component: () => import('@/mobile/page/showroom/detail/IotPage.vue')
        },
        {
            path: '/showroom/digitization',
            name: 'ShowroomPageDigitization',
            component: () => import('@/mobile/page/showroom/detail/DigitizationPage.vue')
        },
        {
            path: '/showroom/intelligent',
            name: 'ShowroomPageIntelligent',
            component: () => import('@/mobile/page/showroom/detail/IntelligentPage.vue')
        },
        {
            path: '/showroom/digitalFactory',
            name: 'ShowroomPageDigitalFactory',
            component: () => import('@/mobile/page/showroom/detail/DigitalFactoryPage.vue')
        },
        {
            path: '/showroom/e1',
            name: 'ShowroomPageE1',
            component: () => import('@/mobile/page/showroom/detail/E1Page.vue')
        },
        {
            path: '/showroom/jdd3',
            name: 'ShowroomPageJDD3',
            component: () => import('@/mobile/page/showroom/detail/JDD3Page.vue')
        },
        {
            path: '/showroom/lean',
            name: 'ShowroomPageLean',
            component: () => import('@/mobile/page/showroom/detail/LeanPage.vue')
        },
        {
            path: '/showroom/polishedEquipment',
            name: 'ShowroomPagePolishedEquipment',
            component: () => import('@/mobile/page/showroom/detail/PolishedEquipmentPage.vue')
        },
        {
            path: '/showroom/glueEquipment',
            name: 'ShowroomPageGlueEquipment',
            component: () => import('@/mobile/page/showroom/detail/GlueEquipmentPage.vue')
        },
        {
            path: '/showroom/sprayEquipment',
            name: 'ShowroomPageSprayEquipment',
            component: () => import('@/mobile/page/showroom/detail/SprayEquipmentPage.vue')
        },
        {
            path: '/showroom/detect',
            name: 'ShowroomPageDetect',
            component: () => import('@/mobile/page/showroom/detail/DetectPage.vue')
        },
        {
            path: '/disclaimer',
            name: 'DisclaimerPage',
            component: () => import('@/mobile/page/disclaimer/DisclaimerPage.vue')
        },
        {
            path: '/customerCase',
            name: 'CustomerCasePage',
            component: () => import('@/mobile/page/customerCase/CustomerCasePage.vue')
        },
        {
            path: '/feedback',
            name: 'FeedbackPage',
            component: () => import('@/mobile/page/customerCase/FeedbackPage.vue')
        },
        {
            path: '/partner/brand',
            name: 'PartnerPage',
            component: () => import('@/mobile/page/partner/PartnerPage.vue')
        },
        {
            path: '/partner/business',
            name: 'BusinessPage',
            component: () => import('@/mobile/page/partner/BusinessPage.vue')
        },
        {
            path: '/products',
            name: 'ProductsPage',
            component: () => import('@/mobile/page/products/ProductsPage.vue')
        },
        {
            path: '/productionLine',
            name: 'ProductionLinePage',
            component: () => import('@/mobile/page/productionLine/ProductionLinePage-Card.vue')
        },
        {
            path: '/productionLine/molding',
            name: 'MoldingPage',
            component: () => import('@/mobile/page/productionLine/MoldingPage-Verbose.vue')
        },
        {
            path: '/productionLine/menShoes',
            name: 'MenShoesPage',
            component: () => import('@/mobile/page/productionLine/MenShoesPage-Verbose.vue')
        },
        {
            path: '/productionLine/lean',
            name: 'LeanPage',
            component: () => import('@/mobile/page/productionLine/LeanPage-Verbose.vue')
        },
        {
            path: '/dedicatedEquipment',
            name: 'DedicatedEquipmentPage',
            component: () => import('@/mobile/page/dedicatedEquipment/DedicatedEquipmentPage-Card.vue')
        },
        {
            path: '/dedicatedEquipment/polishedEquipment',
            name: 'PolishedEquipmentPage',
            component: () => import('@/mobile/page/dedicatedEquipment/PolishedEquipmentPage-Verbose.vue')
        },
        {
            path: '/dedicatedEquipment/glueEquipment',
            name: 'GlueEquipmentPage',
            component: () => import('@/mobile/page/dedicatedEquipment/GlueEquipmentPage-Verbose.vue')
        },
        {
            path: '/dedicatedEquipment/sprayEquipment',
            name: 'SprayEquipmentPage',
            component: () => import('@/mobile/page/dedicatedEquipment/SprayEquipmentPage-Verbose.vue')
        },
        {
            path: '/technology/overview',
            name: 'TechnologyPage',
            component: () => import('@/mobile/page/technology/TechnologyPage.vue')
        },
        {
            path: '/technology/mechanical',
            name: 'MechanicalPage',
            component: () => import('@/mobile/page/technology/MechanicalPage.vue')
        },
        {
            path: '/technology/automation',
            name: 'AutomationPage',
            component: () => import('@/mobile/page/technology/AutomationPage.vue')
        },
        {
            path: '/technology/iot',
            name: 'IotPage',
            component: () => import('@/mobile/page/technology/IotPage.vue')
        },
        {
            path: '/technology/intelligent',
            name: 'IntelligentPage',
            component: () => import('@/mobile/page/technology/IntelligentPage.vue')
        },
        {
            path: '/technology/digitizing',
            name: 'DigitizingPage',
            component: () => import('@/mobile/page/technology/DigitizingPage.vue')
        },
        {
            path: '/companyRelated/companyProfile',
            name: 'CompanyProfilePage',
            component: () => import('@/mobile/page/companyRelated/CompanyProfilePage.vue')
        },
        {
            path: '/companyRelated/developmentHistory',
            name: 'DevelopmentHistoryPage',
            component: () => import('@/mobile/page/companyRelated/DevelopmentHistoryPage.vue')
        },
        {
            path: '/companyRelated/companyCulture',
            name: 'CompanyCulturePage',
            component: () => import('@/mobile/page/companyRelated/CompanyCulturePage.vue')
        },
        {
            path: '/companyRelated/honor',
            name: 'HonorPage',
            component: () => import('@/mobile/page/companyRelated/honor/HonorPage.vue')
        },
        {
            path: '/companyRelated/honor/2020072501',
            name: 'HonorPage1',
            component: () => import('@/mobile/page/companyRelated/honor/HonorPage1.vue')
        },
        {
            path: '/companyRelated/honor/2021030401',
            name: 'HonorPage2',
            component: () => import('@/mobile/page/companyRelated/honor/HonorPage2.vue')
        },
        {
            path: '/companyRelated/honor/2021051401',
            name: 'HonorPage3',
            component: () => import('@/mobile/page/companyRelated/honor/HonorPage3.vue')
        },
        {
            path: '/companyRelated/honor/2021081901',
            name: 'HonorPage4',
            component: () => import('@/mobile/page/companyRelated/honor/HonorPage4.vue')
        },
        {
            path: '/companyRelated/honor/2021082701',
            name: 'HonorPage5',
            component: () => import('@/mobile/page/companyRelated/honor/HonorPage5.vue')
        },
        {
            path: '/companyRelated/honor/2022012601',
            name: 'HonorPage6',
            component: () => import('@/mobile/page/companyRelated/honor/HonorPage6.vue')
        },
        {
            path: '/companyRelated/news',
            name: 'NewsPage',
            component: () => import('@/mobile/page/companyRelated/news/NewsPage.vue')
        },
        {
            path: '/companyRelated/news/2019091701',
            name: 'NewsPage1',
            component: () => import('@/mobile/page/companyRelated/news/NewsPage1.vue')
        },
        {
            path: '/companyRelated/news/2019101601',
            name: 'NewsPage2',
            component: () => import('@/mobile/page/companyRelated/news/NewsPage2.vue')
        },
        {
            path: '/companyRelated/news/2020011501',
            name: 'NewsPage3',
            component: () => import('@/mobile/page/companyRelated/news/NewsPage3.vue')
        },
        {
            path: '/companyRelated/news/2020042501',
            name: 'NewsPage4',
            component: () => import('@/mobile/page/companyRelated/news/NewsPage4.vue')
        },
        {
            path: '/companyRelated/news/2020052201',
            name: 'NewsPage5',
            component: () => import('@/mobile/page/companyRelated/news/NewsPage5.vue')
        },
        {
            path: '/companyRelated/news/2020072501',
            name: 'NewsPage6',
            component: () => import('@/mobile/page/companyRelated/news/NewsPage6.vue')
        },
        {
            path: '/companyRelated/news/2020082101',
            name: 'NewsPage7',
            component: () => import('@/mobile/page/companyRelated/news/NewsPage7.vue')
        },
        {
            path: '/companyRelated/news/2021012001',
            name: 'NewsPage8',
            component: () => import('@/mobile/page/companyRelated/news/NewsPage8.vue')
        },
        {
            path: '/companyRelated/news/2021013001',
            name: 'NewsPage9',
            component: () => import('@/mobile/page/companyRelated/news/NewsPage9.vue')
        },
        {
            path: '/companyRelated/news/2021032401',
            name: 'NewsPage10',
            component: () => import('@/mobile/page/companyRelated/news/NewsPage10.vue')
        },
        {
            path: '/companyRelated/news/2021060201',
            name: 'NewsPage11',
            component: () => import('@/mobile/page/companyRelated/news/NewsPage11.vue')
        },
        {
            path: '/companyRelated/news/2022040901',
            name: 'NewsPage12',
            component: () => import('@/mobile/page/companyRelated/news/NewsPage12.vue')
        },
        {
            path: '/vendingInquiries',
            name: 'VendingInquiriesPage',
            component: () => import('@/mobile/page/vendingInquiries/VendingInquiriesPage.vue')
        },
        //Net
        {
            path: '/net',
            name: 'NetHomePage',
            component: () => import('@/mobile/page/net/home/HomePage.vue')
        },
        {
            path: '/net/partner',
            name: 'NetPartnerPage',
            component: () => import('@/mobile/page/net/partner/PartnerPage.vue')
        },
        {
            path: '/net/technology/overview',
            name: 'NetTechnologyPage',
            component: () => import('@/mobile/page/net/technology/TechnologyPage.vue')
        },
        {
            path: '/net/customerCase',
            name: 'NetCustomerCasePage',
            component: () => import('@/mobile/page/net/customerCase/CustomerCasePage.vue')
        },
        {
            path: '/net/productionLine',
            name: 'NetProductionLinePage',
            component: () => import('@/mobile/page/net/productionLine/ProductionLinePage.vue')
        },
        {
            path: '/net/dedicatedEquipment',
            name: 'NetDedicatedEquipmentPage',
            component: () => import('@/mobile/page/net/dedicatedEquipment/DedicatedEquipmentPage.vue')
        },
        {
            path: '/net/dedicatedEquipment/polishedEquipment',
            name: 'NetPolishedEquipmentPage',
            component: () => import('@/mobile/page/net/dedicatedEquipment/PolishedEquipmentPage.vue')
        },
        {
            path: '/net/dedicatedEquipment/glueEquipment',
            name: 'NetGlueEquipmentPage',
            component: () => import('@/mobile/page/net/dedicatedEquipment/GlueEquipmentPage.vue')
        },
        {
            path: '/net/dedicatedEquipment/sprayEquipment',
            name: 'NetSprayEquipmentPage',
            component: () => import('@/mobile/page/net/dedicatedEquipment/SprayEquipmentPage.vue')
        },
        {
            path: '/net/companyRelated/companyProfile',
            name: 'NetCompanyProfilePage',
            component: () => import('@/mobile/page/net/companyRelated/CompanyProfilePage.vue')
        },
        {
            path: '/net/companyRelated/developmentHistory',
            name: 'NetDevelopmentHistoryPage',
            component: () => import('@/mobile/page/net/companyRelated/DevelopmentHistoryPage.vue')
        },
        {
            path: '/net/companyRelated/companyCulture',
            name: 'NetCompanyCulturePage',
            component: () => import('@/mobile/page/net/companyRelated/CompanyCulturePage.vue')
        },
    ],
}

export default state