<template>
  <div v-if="finish">
    <router-view></router-view>
  </div>
</template>

<!-- <script>

export default {
  name: 'App',
}
</script> -->

<script setup>
import { ref, onMounted } from 'vue';

const finish = ref(false);

onMounted(() => {
  finish.value = true;
  document.querySelector('#opening-animation').style.display = 'none';
})
</script>

<style scoped>

</style>
