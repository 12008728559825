import { createWebHistory, createRouter, createWebHashHistory } from 'vue-router';
import RoutesData from './state';
const history = createWebHistory();
const history2 = createWebHashHistory();
console.log(history2);
const judgeIsPC = window.judgeIsPC;

const router = createRouter({
    history: history, // 路由模式
    routes: judgeIsPC ? RoutesData.pcRoutes : RoutesData.mobileRoutes
})

export default router